import React, { useState } from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../../layouts'
import EmailCollection from '../../components/shared/EmailCollection'
import SectionedIngredients from '../../components/shared/SectionedIngredients'
import Testimonials from '../../components/homepage/Testimonials'
import AddButton from '../../components/shared/Recipes/AddButton'
import { getAllIngredients } from '../../common/ingredients'
import { CartContext } from '../../components/shared/CartProvider'
import SelectRecipes from '../../components/shared/LandingComponents/SelectRecipes'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Groceries from '../../images/svgs/groceries.svg'
import Recipe from '../../images/svgs/recipe.svg'
import Vegetables from '../../images/svgs/vegetables.svg'

import HowItWorks from '../../components/shared/LandingComponents/HowItWorks'

const recipeSlugs = [
  'grilled-romaine-with-baba-ganoush',
  'cauliflower-chickpea-tacos',
  'tahini-quinoa',
  'bulgur-with-vegetables',
  'eggplant-with-crispy-grounds',
  'curry-chickpeas-and-greens',
  'lentil-bolognese'
]


const PlantBasedStarter = ({ data }) => {
  const [selectedRecipes, setSelectedRecipes] = useState([])
  const recipes = data.allContentfulRecipe.nodes.filter(recipe => recipeSlugs.indexOf(recipe.slug) != -1)
  const allIngredients = getAllIngredients(recipes.filter(recipe => selectedRecipes.indexOf(recipe.slug) != -1), null)

  return (
    <IndexLayout>
      <div className="pb-5 mb-5">
        <div className="section header bg-light">
          <div className="container bg-light pt-5">
            <div className="header-index bg-light mb-0 pb-1">
              <div className="row">
                <div className="col-12 col-md-7 py-4 d-flex flex-column justify-content-center">
                  <h1 className="font-weight-bold display-1">
                    Your one stop shop for easy, plant-based meals.
                  </h1>
                  <h4>
                    Pick a recipe, select the groceries you want, and we’ll deliver everything you need to make it at home.
                  </h4>
                  <a className={`btn btn-lg btn-primary mt-3 mr-5 d-print-none mb-5`}
                    onClick={() => scrollTo('#pick-recipes')}>
                    Get started
                </a>
                </div>
                <div className="col-12 col-md-4">
                  <img className="img-fluid bowl" alt="Eating Plants" src={data.groceryImage.childImageSharp.fluid.src} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <EmailCollection />
        <HowItWorks cellData={[
          {
            icon: Recipe, text: "Choose as many (or as few!) recipes as you want. Get ready for dinner tonight or stock up for the whole week.", header: "Recipes"
          },
          {
            icon: Vegetables, text: "We’ll show you what you need to make each dish. Already have certain supplies? Take ‘em off the list. Need more? Stock up!", header: "Ingredients"
          },
          {
            icon: Groceries, text: "Pick a day and time for your delivery. Grocery shopping: ✅", header: "Delivered"
          }
        ]} />
        <Testimonials />
        <div className="section bg-light mb-5">
          <div className="container bg-light position-relative pb-5">
            <img className="position-absolute w-25 d-none d-md-block" style={{ top: '0  px', left: '-30px' }} src={data.lentilImage.childImageSharp.fluid.src} />
            <img className="position-absolute d-none d-md-block w-20" style={{ bottom: '0px', right: '30px' }} src={data.pozoleImage.childImageSharp.fluid.src} />
            <div className="header-index bg-light mb-0 pb-5 pt-5">
              <div className="row pt-0 pt-md-3">
                <div className="col-12 col-md-8 pt-2 d-flex flex-column justify-content-end">
                  <h3 className="font-weight-bold mb-4">
                    Trying to eat more plants?
                  </h3>
                  <p className="font-weight-light">
                    Us too. If you’re looking to live a more plant-based life but just aren’t sure how to get going, do we have some news for you.
                  </p>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column justify-content-center">
                  <img className="img-fluid" src={data.quinoaImage.childImageSharp.fluid.src} />
                </div>
                <div className="col-12">
                  <p className="font-weight-light">
                    ELM exists to make plant-based meal planning a breeze. We learn how often you want to cook at home and how much effort you want to put into your meals.
                    Then we suggest recipes based on your preferences and lifestyle, and bring the ingredients you need straight to your doorstep.
                  </p>
                  <p className="font-weight-light">
                    We know eating the way you want can be hard, it takes planning and your grocery store isn’t always on your side.
                    It can be different, and with ELM it is. Best of all, it’s not more expensive than buying groceries the regular way!✨
                  </p>
                  <p className="font-weight-light">
                    Pick a few recipes and let us do the rest. We can’t wait to hear about the plant-based goodness you create in your kitchen.✨
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SelectRecipes recipes={recipes} setRecipes={setSelectedRecipes} scrollable={true} />
        {selectedRecipes.length > 0 && <>
          <div className="container pt-4" id="pick-ingredients">
            <div className="row">
              <div className="col-12">
                <h2 className="font-weight-bold">Step 2 - Shop for ingredients</h2>
                {selectedRecipes.length == 1 && <p className="small">Everything you need to make this recipe. Select what you need below.</p>}
                {selectedRecipes.length > 1 && <p className="small">Everything you need to make these {selectedRecipes.length} recipes. Select what you need below.</p>}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-md-4">
                <AddButton recipe={recipes.filter(recipe => selectedRecipes.indexOf(recipe.slug) != -1)} />
              </div>
            </div>
            <SectionedIngredients ingredients={allIngredients} />
          </div>
          <section className="pick-ingredients">
            <div className="container">
              <CartContext.Consumer>
                {(cart) => {
                  return (
                    <div className="row pt-4">
                      <div className="col-8 col-md-4 mx-auto text-center">
                        {cart.contents.length > 0 && <>
                          <button className="btn btn-primary btn-block" onClick={cart.toggleCart}>Checkout</button>
                          <p className="smaller">{'\u00A0'}</p>
                        </>
                        }
                        {cart.contents.length == 0 && <>
                          <button className="btn btn-primary btn-block disabled">Checkout</button>
                          <p className="smaller">Add some items to your cart</p>
                        </>
                        }
                      </div>
                    </div>
                  )
                }}
              </CartContext.Consumer>
            </div>
          </section>
        </>
        }
      </div>
    </IndexLayout >
  )
}

export const query = graphql`
  query {
    groceryImage: file(relativePath: { eq: "images/Ingredients.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    lentilImage: file(relativePath: { eq: "food/potato-lentil.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    quinoaImage: file(relativePath: { eq: "food/tahini-quinoa-2.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    pozoleImage: file(relativePath: { eq: "food/pozole.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        rectangularImage {
          file {
            url
          }
          fluid {
            src
          }
        }
        ingredients {
          amount
          ingredient {
            name
            sku
            priceCents
            id
            section
            quantityDescription
            brand
            slug
            picture {
              fluid(quality: 100, maxWidth:150) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default PlantBasedStarter
